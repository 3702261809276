<template>
    <v-row no-gutters>
        <v-col cols="12">
            <v-row justify="center" class="py-5 mt-10">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-alert type="warning" v-if="!user.xentri_id || !user.account_id">
                        <router-link :to="{ name: 'user-check' }" class="white--text">{{ whatRequiresAttention }}</router-link>
                    </v-alert>
                    <v-card elevation="2" class="my-6">
                        <v-app-bar color="#3F51B5" dark flat dense>
                            <v-app-bar-title>Dashboard</v-app-bar-title>
                            <!-- <v-spacer/>
                            <v-menu offset-y left>
                                <template #activator="{ on }">
                                    <v-btn icon v-on="on">
                                        <font-awesome-icon :icon="['far', 'ellipsis-v']" style="font-size: 20px;" fixed-width/>
                                    </v-btn>
                                </template>
                                <v-list class="ma-0 pa-0">
                                    <v-list-item-group>
                                    <v-list-item :to="{ name: 'user-settings' }">
                                        <v-list-item-title>Settings</v-list-item-title>
                                    </v-list-item>
                                    </v-list-item-group>
                                </v-list>
                            </v-menu> -->
                        </v-app-bar>
                        <v-card-text class="pb-8">
                            <p class="text-overline mt-5">OpenVPN</p>
                            <p class="help">If you haven't connected yet, get your software, settings, and password below and start browsing privately!</p>
                            <ul>
                                <li><router-link :to="{ name: 'user-openvpn-software' }">software</router-link></li>
                                <li><router-link :to="{ name: 'user-openvpn-config' }">settings</router-link></li>
                                <li><router-link :to="{ name: 'user-openvpn-password' }">password</router-link></li>
                            </ul>
                            <!-- <p class="mb-5 pb-0">
                                Get
                                <router-link :to="{ name: 'user-openvpn-software' }">software</router-link>,
                                <router-link :to="{ name: 'user-openvpn-config' }">settings</router-link>, and
                                <router-link :to="{ name: 'user-openvpn-password' }">password</router-link>.
                            </p> -->
                            <!-- <p class="text-overline mb-0 mt-5">Profile</p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'user-profile' }">My profile</router-link>
                            </p>
                            <p class="text-overline mb-0 mt-10">Account</p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'user-account-dashboard' }">My account</router-link>
                            </p> -->
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import { mapState /* , mapGetters */ } from 'vuex';

export default {
    computed: {
        ...mapState({
            account: (state) => state.account,
            user: (state) => state.user,
            session: (state) => state.session,
        }),
        whatRequiresAttention() {
            const list = [];
            if (!this.user.xentri_id) {
                list.push('profile');
            }
            if (!this.user.account_id) {
                list.push('account');
            }
            if (list.length === 0) {
                return '';
            }
            const nouns = list.join(' and ');
            if (list.length === 1) {
                return `Your ${nouns} requires attention.`;
            }
            return `Your ${nouns} require attention.`;
        },
        downloadURL() {
            return `/api/browser/user/${this.user.id}/download/openvpn/CryptiumVPN.ovpn`;
        },
    },
    methods: {
        settings() {
            this.$router.push({ name: 'user-settings' });
        },
        async createOpenvpnPassword() {
            try {
                const response = await this.$client.user(this.user.id).user.createOpenvpnPassword();
                console.log(`Got response from createOpenvpnPassword: ${JSON.stringify(response)}`);
            } catch (err) {
                console.log(`Failed to create openvpn password: ${err.message}`);
            }
        },
    },
};
</script>
